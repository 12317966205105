import utils from "./utils";

const canvas = document.querySelector("canvas");
const ctx = canvas.getContext("2d");

canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

// Event Listeners
addEventListener("resize", () => {
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  init();
});

// Objects
class Star {
  constructor(x, y, radius, color) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.color = color;
    this.clickForce = 10;
    this.velocity = {
      x: utils.randomIntFromRange(-8, 8),
      y: 10,
    };
    this.gravity = 0.25;
    this.friction = 0.6;
    this.mass = 1;
    this.clicked = function () {
      this.shatter();
      this.velocity.y = -1 * Math.abs(this.velocity.y) - this.clickForce;
      this.velocity.x = -1 * this.velocity.x;
    };
  }

  shatter() {
    this.radius -= 12;
    for (let i = 0; i < 5; i++) {
      const ministar = new MiniStar(this.x, this.y, 4, "cyan");
      ministars.push(ministar);
    }
  }

  draw() {
    ctx.save();
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    // ctx.shadowColor = this.color;
    // ctx.shadowBlur = 50;
    ctx.strokeStyle = this.color;
    ctx.stroke();
    // ctx.fillStyle = this.color;
    // ctx.fill();
    ctx.closePath();
    ctx.restore();
  }

  update(stars) {
    this.draw();
    let gravitate = true;

    for (let i = 0; i < stars.length; i++) {
      const otherStar = stars[i];
      if (otherStar === this) continue;

      if (
        utils.distance(this.x, this.y, otherStar.x, otherStar.y) <=
        this.radius + otherStar.radius
      ) {
        gravitate = false;

        utils.resolveCollision(this, otherStar);

        this.shatter();
        otherStar.shatter();
      }
    }

    if (
      this.y + this.velocity.y + this.radius >
      canvas.height
      // add this to seal the cealing
      // || this.y + this.velocity.y - this.radius < 0
    ) {
      this.shatter();
      this.velocity.y = -this.velocity.y * this.friction;
    } else if (gravitate) {
      this.velocity.y += this.gravity;
    }

    if (
      this.x + this.velocity.x + this.radius > canvas.width ||
      this.x + this.velocity.x - this.radius < 0
    ) {
      this.velocity.x = -this.velocity.x * this.friction;
      this.shatter();
    }

    if (this.velocity.y.toFixed(3) === "0.000") {
      this.velocity.x = this.velocity.x * 0.9;
    }

    this.y += this.velocity.y;
    this.x += this.velocity.x;
  }
}

class MiniStar {
  constructor(x, y, radius, color) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.color = color;
    this.velocity = {
      x: utils.randomIntFromRange(-5, 5),
      y: utils.randomIntFromRange(-15, 15),
    };
    this.gravity = 0.2;
    this.friction = 0.3;
    this.lifespan = 80;
    this.ttl = this.lifespan;
    this.opacity = 1;
  }

  draw() {
    ctx.save();
    ctx.globalAlpha = this.opacity;
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    ctx.shadowColor = this.color;
    ctx.shadowBlur = 5;
    ctx.fillStyle = this.color;
    ctx.fill();
    ctx.closePath();
    ctx.restore();
    // ctx.globalAlpha = 1;
  }

  update() {
    this.draw();

    if (this.y + this.velocity.y + this.radius > canvas.height) {
      this.velocity.y = -this.velocity.y * this.friction;
    } else {
      this.velocity.y += this.gravity;
    }

    if (
      this.x + this.velocity.x + this.radius >= canvas.width ||
      this.x - this.radius <= 0
    ) {
      this.velocity.x = -this.velocity.x * this.friction;
    }

    this.y += this.velocity.y;
    this.x += this.velocity.x;
    this.ttl -= 1;
    this.opacity = this.ttl / this.lifespan;
  }
}

// Implementation
let animation;
let stars;
let ministars;
let backgroundstars;
let interval = 100;
let ticker = 0;
function init() {
  stars = [];
  ministars = [];
  backgroundstars = [];

  // Background stars
  for (let i = 0; i < 300; i++) {
    const x = Math.random() * canvas.width;
    const y = Math.random() * canvas.height;
    const radius = Math.random() * 10;

    backgroundstars.push(new Star(x, y, radius, "#fff"));
  }

  // Set amount of stars

  // for (let j = 0; j < 50; j++) {
  //   let attempts = 100;
  //   const radius = 72;
  //   let x = utils.randomIntFromRange(radius, canvas.width - radius);
  //   let y = utils.randomIntFromRange(radius, canvas.height - radius);

  //   if (j !== 0) {
  //     for (let i = 0; i < stars.length; i++) {
  //       if (!attempts) break;
  //       if (utils.distance(x, y, stars[i].x, stars[i].y) < radius * 2) {
  //         x = Math.max(radius, Math.random() * canvas.width - radius);
  //         y = Math.max(radius, Math.random() * canvas.height - radius);
  //         attempts--;
  //         i = -1;
  //       }
  //     }
  //   }

  //   // Don't push if attempts are exhausted
  //   if (!attempts) continue;

  //   const star = new Star(x, y, radius, "#0ff");
  //   stars.push(star);
  // }

  canvas.addEventListener("click", function (e) {
    const x = e.pageX - canvas.offsetLeft;
    const y = e.pageY - canvas.offsetTop;

    stars.forEach((star) => {
      if (utils.distance(x, y, star.x, star.y) < star.radius) {
        star.clicked();
      }
    });
  });
}

// Animation Loop
function animate() {
  // Use this if animation should end

  // if (
  //   !ministars.length &&
  //   stars.every((star) => {
  //     return Math.abs(star.velocity.x.toFixed(3)) < 0.05;
  //   })
  // ) {
  //   cancelAnimationFrame(animate);
  // } else {
  //   animation = requestAnimationFrame(animate);
  // }

  // Use this for continuous animation

  animation = requestAnimationFrame(animate);

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
  gradient.addColorStop(0, "#101010");
  gradient.addColorStop(1, "#010101");

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // backgroundstars.forEach((backgroundstar) => {
  //   backgroundstar.draw();
  // });

  stars.forEach((star, index) => {
    if (star.radius === 0) {
      stars.splice(index, 1);
    }

    star.update(stars);
  });

  ministars.forEach((ministar, index) => {
    if (ministar.opacity === 0) {
      ministars.splice(index, 1);
    }

    ministar.update();
  });

  // Constant star rain

  if (ticker % interval === 0) {
    let attempts = 100;
    const radius = 120;
    let x = utils.randomIntFromRange(radius, canvas.width - radius);
    let y = -1 * radius;
    // let y = utils.randomIntFromRange(radius, canvas.height - radius);

    if (stars.length > 1) {
      for (let i = 0; i < stars.length; i++) {
        if (!attempts) break;
        if (utils.distance(x, y, stars[i].x, stars[i].y) < radius * 2) {
          x = Math.max(radius, Math.random() * canvas.width - radius);
          // y = Math.max(radius, Math.random() * canvas.height - radius);
          attempts--;
          i = -1;
        }
      }
    }

    // Don't push if attempts are exhausted
    if (attempts !== 0) {
      const star = new Star(x, y, radius, "#0ff");
      stars.push(star);
    }
  }

  ticker++;
}

init();
animation = requestAnimationFrame(animate);
