function randomIntFromRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function randomColor(colors) {
  return colors[Math.floor(Math.random() * colors.length)];
}

function distance(x1, y1, x2, y2) {
  const xDist = x2 - x1;
  const yDist = y2 - y1;

  return Math.hypot(xDist, yDist);
}

function rotateVelocities(velocity, angle) {
  const rotatedVelocities = {
    x: velocity.x * Math.cos(angle) - velocity.y * Math.sin(angle),
    y: velocity.x * Math.sin(angle) + velocity.y * Math.cos(angle),
  };

  return rotatedVelocities;
}

function resolveCollision(item, secondItem) {
  const xVelocityDiff = item.velocity.x - secondItem.velocity.x;
  const yVelocityDiff = item.velocity.y - secondItem.velocity.y;

  const xDist = secondItem.x - item.x;
  const yDist = secondItem.y - item.y;

  // Prevent accidental overlap of items
  if (xVelocityDiff * xDist + yVelocityDiff * yDist >= 0) {
    // Grab angle between the two colliding items
    const angle = -Math.atan2(secondItem.y - item.y, secondItem.x - item.x);

    // Store mass in var for better readability in collision equation
    const m1 = item.mass;
    const m2 = secondItem.mass;

    // Velocity before equation
    const u1 = rotateVelocities(item.velocity, angle);
    const u2 = rotateVelocities(secondItem.velocity, angle);

    // Velocity after 1d collision equation
    const v1 = {
      x: (u1.x * (m1 - m2)) / (m1 + m2) + (u2.x * 2 * m2) / (m1 + m2),
      y: u1.y,
    };
    const v2 = {
      x: (u2.x * (m1 - m2)) / (m1 + m2) + (u1.x * 2 * m2) / (m1 + m2),
      y: u2.y,
    };

    // Final velocity after rotating axis back to original location
    const vFinal1 = rotateVelocities(v1, -angle);
    const vFinal2 = rotateVelocities(v2, -angle);

    // Swap item velocities for realistic bounce effect
    item.velocity.x = vFinal1.x;
    item.velocity.y = vFinal1.y;

    secondItem.velocity.x = vFinal2.x;
    secondItem.velocity.y = vFinal2.y;
  }
}

module.exports = {
  randomIntFromRange,
  randomColor,
  distance,
  rotateVelocities,
  resolveCollision,
};
